import { createStore } from "vuex";
import API from "../services/api";
import moment from "moment";

import account from "./modules/account";
import form from "./modules/form";
import lead from "./modules/lead";
import profile from "./modules/profile";

export default createStore({
    modules: {
        account,
        form,
        lead,
        profile,
    },

    state: {
        alert: {},
        s: {
            status: null,
            findme: null,
            category: null,
            type: null,
            canceled: null,
            month: moment().startOf("month").format("YYYY-MM-DD"),
            start: null,
            end: null,
            popup: null,
            clear: null,
        },
    },

    mutations: {
        setAlert: (state, alert) => {
            state.alert = alert;
        },
        setSelect: (state, masters) => {
            state.select_masters = masters;
        },
        setSettings(state, s) {
            localStorage.setItem("s", JSON.stringify(s));
            state.s = s;
        },
    },

    actions: {
        getSelects(ctx) {
            API.GET("profile/selects").then((res) => {
                ctx.commit("setSelect", res.data.workers);
            });
        },

        loadSettings(ctx) {
            let s = localStorage.getItem("s");
            if (s) {
                ctx.commit("setSettings", JSON.parse(s));
            }
        },

        setSettings(ctx, s) {
            ctx.commit("setSettings", s);
        },

        clearSettings(ctx) {
            localStorage.removeItem("s");
            let s = {
                status: null,
                findme: null,
                category: null,
                type: null,
                canceled: null,
                month: moment().startOf("month").format("YYYY-MM-DD"),
                start: null,
                end: null,
                popup: null,
                clear: null,
            };
            ctx.commit("setSettings", s);
        },

        setPopup(ctx, popup) {
            ctx.state.s.popup = popup;
            ctx.commit("setSettings", ctx.state.s);
        },

        closePopup(ctx) {
            ctx.state.s.popup = null;
            ctx.commit("setSettings", ctx.state.s);
        },
    },

    getters: {
        alert(state) {
            return state.alert;
        },
        s(state) {
            return state.s;
        },
        selectMonth(state) {
            return state.select_month;
        },
        selectMonthMin(state) {
            return state.select_monthMin;
        },
        selectWeekDay(state) {
            return state.select_weekday;
        },
        popup(state) {
            return state.popup;
        },
        progressBar(state) {
            return state.progressBar;
        },
    },
});
