<template>
    <div class="w-full">
        <div class="bg-white p-4">
            <div class="flex flex-col lg:flex-row gap-4 justify-between items-center">
                <h2 class="text-sm text-slate-500">
                    ЛИДЫ <sup>{{ leads?.length }}</sup>
                </h2>

                <input
                    type="text"
                    class="hidden lg:visible h-8 w-1/3 px-3 py-2 text-sm bg-slate-100"
                    placeholder="Название или ФИО гостя, email, телефон"
                    v-model="s.findme"
                    @input="findLead(s)"
                />

                <div class="flex gap-2">
                    <div
                        @click="filterLead([1])"
                        class="w-auto lg:w-20 text-yellow-400 border border-yellow-400 hover:bg-yellow-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                    >
                        Бронь
                    </div>
                    <div
                        @click="filterLead([5])"
                        class="w-auto lg:w-20 text-green-400 border border-green-400 hover:bg-green-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                    >
                        Активна
                    </div>
                    <div
                        @click="filterLead([7, 8, 9])"
                        class="w-auto lg:w-20 text-red-400 border border-red-400 hover:bg-red-300 hover:text-white text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                    >
                        Отменена
                    </div>

                    <div class="w-auto lg:w-20">
                        <div
                            v-if="s.clear"
                            @click="clear()"
                            class="w-auto lg:w-20 text-white bg-gray-400 hover:bg-gray-300 text-xs lg:text-sm text-center p-1 rounded cursor-pointer"
                        >
                            Сброс
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white p-4 mt-4">
            <div class="hidden lg:grid lg:grid-cols-12 mb-4 px-2 text-xs text-slate-400">
                <p>#</p>
                <p class="col-span-2">ФИО</p>
                <p class="col-span-2">Телефон</p>
                <p class="col-span-2">Email</p>
                <p class="col-span-3">Комментарий</p>
                <p class="text-right">Дата</p>
                <p class="text-right">Статус</p>
            </div>
            <template v-for="item in leads" :key="item.id">
                <div class="grid grid-cols-3 lg:grid-cols-12 items-center mb-px p-2 text-sm tabular-nums hover:bg-slate-100 odd:bg-slate-50">
                    <p>{{ item.id }}</p>

                    <div class="col-span-2 lg:col-span-2 flex gap-2">
                        <template v-for="field in item?.fields" :key="field.id">
                            <p v-if="field.name == 'name'">{{ field.val }}</p>
                            <p v-if="field.name == 'secondname'">{{ field.val }}</p>
                        </template>
                    </div>

                    <div class="col-span-2 lg:col-span-2">
                        <template v-for="field in item?.fields" :key="field.id">
                            <p v-if="field.name == 'phone'">{{ field.val }}</p>
                        </template>
                    </div>

                    <div class="col-span-2 lg:col-span-2">
                        <template v-for="field in item?.fields" :key="field.id">
                            <p v-if="field.name == 'email'">{{ field.val }}</p>
                        </template>
                    </div>

                    <div class="col-span-3 lg:col-span-3">
                        <template v-for="field in item?.fields" :key="field.id">
                            <p v-if="field.name == 'message'">{{ field.val }}</p>
                        </template>
                    </div>

                    <p class="lg:text-right col-span-3 lg:col-span-1">{{ moment(item.create_at).format("DD.MM.YYYY") }}</p>

                    <div class="flex lg:justify-end items-center col-span-3 lg:col-span-1">
                        <div
                            class="circle mr-4"
                            :class="{
                                '!bg-yellow-400 ': item.status == 1,
                                '!bg-red-400 ': [7, 8, 9].includes(item.status),
                                '!bg-green-400 ': item.status == 5,
                            }"
                        ></div>
                        <div class="w-5">
                            <div v-if="item.status < 7" class="flex justify-end" @click="(popup = 'cancel'), (currentLead = item)"><IconClose /></div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <template v-if="popup == 'cancel'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-black text-white">ОТМЕНИТЬ ЛИД</h3>

            <div class="flex flex-col p-10 text-center">
                <p>Вы точно хотите отменить лид?</p>

                <p class="text-lg mt-8">{{ currentLead.event.name }}</p>

                <p class="text-lg">{{ currentLead.profile.name }} {{ currentLead.profile.secondname }}</p>
                <p>{{ currentLead.profile.email }}</p>
                <p>{{ currentLead.profile.phone }}</p>

                <p class="text-lg font-bold">{{ currentLead.price }} ₽</p>

                <p>{{ moment(currentLead.create_at).format("DD.MM.YYYY") }}</p>

                <div class="flex justify-center mt-8">
                    <div class="btn btn-red py-2 px-6" @click="cancelLead(currentLead), (popup = '')">Отменить лид</div>
                </div>
            </div>
        </div>
        <div @click="popup = ''" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "LeadsPage",

    data() {
        return {
            currentLead: {},
            popup: "",
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "leads"]),
    },

    methods: {
        ...mapActions(["setPopup", "closePopup", "setSettings", "clearSettings", "getLeads", "findLead", "fillLead", "cancelLead"]),

        filterLead(status) {
            console.log("filterLead", status);
            this.s.status = status;
            this.setSettings(this.s);
            this.findLead(this.s);
        },

        clear() {
            this.clearSettings();
            this.fillLead();
        },
    },

    mounted() {
        this.getLeads();
    },
};
</script>
