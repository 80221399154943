<template>
    <template v-if="toggleMenu">
        <div class="w-full h-screen justify-center bg-black fixed z-40">
            <ul v-if="profile?.id" class="flex flex-col w-full h-screen justify-center items-center text-center gap-10">
                <div v-if="profile?.picture" class="h-24 mb-10">
                    <a
                        class="block bg-white border border-white w-24 h-24 my-2 rounded-full overflow-hidden relative cursor-pointer"
                        @click="$router.push('/profile'), (toggleMenu = false)"
                    >
                        <img class="w-full h-full object-cover" :src="'https://formapi.igoevent.com/uploads/users/mini/' + profile?.picture" />
                    </a>
                </div>

                <li>
                    <a @click="$router.push('/forms'), (toggleMenu = false)" class="text-white/80 cursor-pointer">Формы</a>
                </li>
                <li>
                    <a @click="$router.push('/leads'), (toggleMenu = false)" class="text-white/80 cursor-pointer">Лиды</a>
                </li>
            </ul>

            <ul v-else class="flex flex-col w-full h-screen justify-center items-center text-center gap-10">
                <li>
                    <a @click="$router.push('/login'), (toggleMenu = false)" class="text-white/80 cursor-pointer">Вход</a>
                </li>
            </ul>
        </div>
        <div v-if="profile?.picture" class="h-12 hidden lg:block">
            <a
                class="block bg-white border border-white w-8 h-8 my-2 mx-auto rounded-full overflow-hidden relative cursor-pointer"
                @click="$router.push('/profile')"
            >
                <img class="w-full h-full object-cover" :src="'https://formapi.igoevent.com/uploads/users/mini/' + profile?.picture" />
            </a>
        </div>
    </template>

    <div class="flex justify-between items-center container mx-auto h-20 px-4">
        <div class="flex justify-between items-center">
            <svg @click="$router.push('/')" class="w-32 h-8 mr-2 cursor-pointer">
                <use xlink:href="@/assets/logo.svg#logo"></use>
            </svg>

            <div class="flex justify-between items-center text-xl font-bold text-white ml-4">FORM</div>
        </div>

        <div class="hidden w-full lg:flex lg:justify-center lg:items-center">
            <ul v-if="profile?.id" class="flex justify-between items-center text-center h-20 gap-10">
                <li>
                    <a @click="$router.push('/forms')" class="items-center text-sm text-white/80 hover:text-white cursor-pointer">Формы</a>
                </li>
                <li>
                    <a @click="$router.push('/leads')" class="items-center text-sm text-white/80 hover:text-white cursor-pointer">Лиды</a>
                </li>
                <li>
                    <a @click="$router.push('/profile')" class="items-center text-sm text-white/80 hover:text-white cursor-pointer">Профиль</a>
                </li>
            </ul>

            <ul v-else class="flex justify-between items-center text-center h-20 gap-10">
                <li>
                    <a @click="$router.push('/login')" class="items-center text-sm text-white/80 hover:text-white cursor-pointer">Вход</a>
                </li>
            </ul>
        </div>
        <div v-if="profile?.picture" class="h-12 hidden lg:block">
            <a
                class="block bg-white border border-white w-8 h-8 my-2 mx-auto rounded-full overflow-hidden relative cursor-pointer"
                @click="$router.push('/profile')"
            >
                <img class="w-full h-full object-cover" :src="'https://formapi.igoevent.com/uploads/users/mini/' + profile?.picture" />
            </a>
        </div>

        <div v-if="toggleMenu" class="flex flex-col w-6 relative z-50 text-white/80 lg:hidden" @click="toggleMenu = !toggleMenu">
            <IconClose size="12" />
        </div>

        <div v-else class="flex flex-col w-6 relative z-50 lg:hidden" @click="toggleMenu = !toggleMenu">
            <span class="w-full h-[2px] mb-[6px] bg-white"></span>
            <span class="w-full h-[2px] mb-[6px] bg-white"></span>
            <span class="w-full h-[2px] mb-[6px] bg-white"></span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "iMenu",

    data() {
        return {
            toggleMenu: false,
        };
    },

    computed: {
        ...mapGetters(["profile"]),
    },
};
</script>
