<template>
    <div class="w-full">
        <div class="bg-white p-4">
            <div class="flex gap-4 justify-between items-center">
                <h2 class="text-sm text-slate-500">
                    ФОРМЫ <sup>{{ forms_length }}</sup>
                </h2>

                <div class="hidden lg:flex">
                    <input
                        type="text"
                        class="h-8 w-1/2 px-3 py-2 text-sm bg-slate-100"
                        placeholder="Название"
                        v-model="s.findme"
                        @input="findForm(s)"
                    />

                    <div class="w-20">
                        <div
                            v-if="s.clear"
                            @click="clear()"
                            class="w-14 text-white bg-gray-400 hover:bg-gray-300 text-sm text-center p-1 rounded cursor-pointer"
                        >
                            Сброс
                        </div>
                    </div>
                </div>
                <div class="btn btn-green" @click="setPopup('addForm')"><IconPlus /> <span class="ml-2">Создать</span></div>
            </div>
        </div>

        <div class="bg-white p-4 mt-4">
            <div class="hidden lg:grid lg:grid-cols-9 mb-4 px-2 text-xs text-slate-400">
                <p class="col-span-3">Название</p>
                <p class="col-span-3">Токен</p>
                <p class="text-center">Создана</p>
                <p class="text-center">Url</p>
                <p class="text-right">Статус</p>
            </div>
            <template v-for="item in forms" :key="item.id">
                <template v-if="item.del == 0">
                    <div class="grid grid-cols-3 lg:grid-cols-9 items-center mb-px p-2 text-sm tabular-nums hover:bg-slate-100 odd:bg-slate-50">
                        <div class="flex items-center col-span-3">
                            #{{ item.id }}
                            <p class="text-lg ml-4">{{ item.name }}</p>
                        </div>

                        <p class="col-span-3 lg:col-span-3">{{ item.token }}</p>

                        <p class="col-span-3 lg:col-span-1 text-center">{{ moment(item.create_at).format("DD.MM.YYYY") }}</p>

                        <p class="flex justify-center gap-4 text-xs" @click="gotoUrl(item.url)">
                            <IconHome />
                            <!-- <a href="{{ item.url }}">{{ item.url }}</a> -->
                        </p>

                        <div class="flex justify-end items-center">
                            <div class="circle mr-4" :class="{ '!bg-green-400 ': item.status == 1 }"></div>
                            <p class="flex justify-end" @click="editForm(item)"><IconEdit /></p>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div class="px-4 mt-20">
            <div class="flex justify-between items-center">
                <h2 class="text-sm text-slate-500">АРХИВ</h2>
            </div>
        </div>

        <div class="bg-white p-4 mt-4">
            <div class="hidden lg:grid lg:grid-cols-9 mb-4 px-2 text-xs text-slate-400">
                <p class="col-span-3">Название</p>
                <p class="col-span-3">Токен</p>
                <p class="text-center">Создана</p>
                <p class="text-center">Url</p>
                <p class="text-right">Восстановить</p>
            </div>
            <template v-for="item in forms" :key="item.id">
                <template v-if="item.del == 1">
                    <div class="grid grid-cols-3 lg:grid-cols-9 items-center mb-px p-2 text-sm tabular-nums hover:bg-slate-100 odd:bg-slate-50">
                        <div class="flex items-center col-span-3">
                            #{{ item.id }}
                            <p class="text-lg ml-4">{{ item.name }}</p>
                        </div>

                        <p class="col-span-3 lg:col-span-3">{{ item.token }}</p>

                        <p class="col-span-3 lg:col-span-1 text-center">{{ moment(item.create_at).format("DD.MM.YYYY") }}</p>

                        <p class="flex justify-center gap-4 text-xs" @click="gotoUrl(item.url)">
                            <IconHome />
                            <!-- <a href="{{ item.url }}">{{ item.url }}</a> -->
                        </p>

                        <div class="flex justify-end items-center">
                            <p class="flex justify-end" @click="recoverForm(item)"><IconRe /></p>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>

    <template v-if="s.popup == 'addForm'"><AddForm /></template>
    <template v-if="s.popup == 'editForm'"><EditForm /></template>

    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import AddForm from "../components/AddForm.vue";
import EditForm from "../components/EditForm.vue";

export default {
    name: "FormsPage",

    components: { AddForm, EditForm },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "forms"]),

        forms_length() {
            let i = 0;
            this.forms?.forEach((item) => {
                if (item.del == 0) {
                    i++;
                }
            });
            return i;
        },
    },

    methods: {
        ...mapActions(["setPopup", "closePopup", "setSettings", "clearSettings", "getForms", "editForm", "findForm", "fillForm", "recoverForm"]),

        clear() {
            this.clearSettings();
            this.fillForm();
        },

        gotoLanding(id) {
            let route = this.$router.resolve({ path: "/l/" + id });
            window.open(route.href);
        },

        gotoUrl(url) {
            window.open(url);
        },
    },

    mounted() {
        this.getForms();
    },
};
</script>
<style>
.wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
}
</style>
