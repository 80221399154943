import API from "../../services/api";
import router from "@/services/router";

export default {
    state: {
        forms: [],
        formsF: [],
        form: {},
    },

    mutations: {
        setForms(state, forms) {
            state.forms = forms;
            state.formsF = forms;
        },
        setFormsF(state, forms) {
            state.formsF = forms;
        },
        setForm(state, form) {
            localStorage.setItem("form_id", form.id);
            state.form = form;
        },
    },

    actions: {
        getForms(ctx) {
            ctx.dispatch("getProfile");
            API.GET("form/list").then((res) => {
                ctx.commit("setForms", res.data.forms);
            });
        },

        getLanding(ctx, id) {
            API.GET("form/one", { id: id }).then((res) => {
                ctx.commit("setForm", res.data.form);
            });
        },

        getSuccess(ctx) {
            // TODO вывести чек???? или что???
            let id = localStorage.getItem("form_id");
            if (id && id > 0) {
                API.GET("form/one", { id: id }).then((res) => {
                    ctx.commit("setForm", res.data.form);
                    // ctx.commit("setPayment", res.data.payment);
                });
            }
        },

        fillForm(ctx) {
            ctx.commit("setFormsF", ctx.state.forms);
        },

        findForm(ctx, s) {
            if (!ctx.state.forms) {
                return null;
            }

            let list = ctx.state.forms;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => item.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1);
            }

            if (list.length != ctx.state.forms.length) {
                s.clear = true;
            }

            ctx.commit("setFormsF", list);
        },

        editForm(ctx, form) {
            ctx.commit("setForm", form);
            ctx.dispatch("setPopup", "editForm");
        },

        saveForm(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id ?? null);
            formData.append("account_id", data.account_id ?? ctx.rootState.profile.account);
            formData.append("biblioform_id", data.biblioform_id ?? null);
            formData.append("form_id", data.form_id ?? null);
            formData.append("name", data.name);
            formData.append("url", data.url ?? null);
            formData.append("description", data.description ?? null);
            formData.append("stop_at", data.stop_at ?? null);
            formData.append("type1", parseInt(data.type1));
            formData.append("price1", parseInt(data.price1));
            formData.append("type2", parseInt(data.type2));
            formData.append("price2", parseInt(data.price2));
            formData.append("type3", parseInt(data.type3));
            formData.append("price3", parseInt(data.price3));
            formData.append("type4", parseInt(data.type4));
            formData.append("price4", parseInt(data.price4));
            formData.append("type5", parseInt(data.type5));
            formData.append("price5", parseInt(data.price5));
            if (data.status === true || data.status == 1) {
                formData.append("status", 1);
            } else {
                formData.append("status", 0);
            }
            formData.append("del", data.del ?? null);

            API.POST("form/create", formData).then((res) => {
                console.log(res.data);
                ctx.commit("setForms", res.data.forms);
            });
        },

        delForm(ctx, form) {
            let formData = new FormData();
            formData.append("id", form.id);
            API.POST("form/delete", formData).then((res) => {
                // let forms = ctx.state.forms.filter((item) => item.id !== form.id);
                ctx.commit("setForms", res.data.forms);
            });
        },

        recoverForm(ctx, form) {
            let formData = new FormData();
            formData.append("id", form.id);
            API.POST("form/recover", formData).then((res) => {
                ctx.commit("setForms", res.data.forms);
            });
        },

        buyForm(ctx, form) {
            let formData = new FormData();
            formData.append("form_id", form.form_id);
            formData.append("type", form.type);
            formData.append("name", form.name);
            formData.append("secondname", form.secondname);
            formData.append("email", form.email);
            formData.append("phone", form.phone);
            API.POST("pay/pay", formData).then((res) => {
                console.log(res.data);
                ctx.commit("setAlert", res.data.alert);
                if (res.data.code == 210) {
                    router.push("/success");
                }
                if (res.data.code == 200) {
                    window.location.replace(res.data.redirect);
                }
            });
        },
    },

    getters: {
        forms(state) {
            return state.formsF;
        },
        form(state) {
            return state.form;
        },
    },
};
