import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import MainPage from "@/pages/Main";
import LoginPage from "@/pages/Login";
import ProfilePage from "@/pages/Profile";
import AccountPage from "@/pages/Account";
import FormsPage from "@/pages/Forms";
import LeadsPage from "@/pages/Leads";

const routes = [
    {
        path: "/",
        name: "home",
        meta: { title: "Подписки на igoevent.com" },
        component: MainPage,
    },
    {
        path: "/login",
        name: "login",
        meta: { title: "Подписки на igoevent.com" },
        component: LoginPage,
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfilePage,
    },
    {
        path: "/account",
        name: "account",
        component: AccountPage,
    },
    {
        path: "/forms",
        name: "forms",
        meta: { title: "Формы" },
        component: FormsPage,
    },
    {
        path: "/leads",
        name: "leads",
        meta: { title: "Лиды" },
        component: LeadsPage,
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "Подписки на igoevent.com";

    if (
        // make sure the user is authenticated
        !authService.isLogged() &&
        // Avoid an infinite redirect
        to.name !== "login" &&
        to.name !== "home"
    ) {
        // redirect the user to the login page
        return { name: "login" };
    }
});

export default router;
