export default {
    state: {
        account: [],
    },

    mutations: {
        setAccount(state, account) {
            state.account = account;
        },
    },

    actions: {},

    getters: {
        account(state) {
            return state.account;
        },
    },
};
