<template>
    <div class="container mx-auto bg-white min-h-[calc(100vh-112px)] text-center flex flex-col justify-center">
        <div>
            <span>СЕРВИС ФОРМ</span>
            <h1 class="text-5xl font-bold">Я иду на событие!</h1>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "MainPage",

    data() {
        return {
            connection: {},
        };
    },

    methods: {
        ...mapActions([]),
    },

    mounted() {},
};
</script>
