import API from "../../services/api";

export default {
    state: {
        leads: [],
        leadsF: [],
        lead: {},
    },

    mutations: {
        setLeads(state, leads) {
            state.leads = leads;
            state.leadsF = leads;
        },
        setLeadsF(state, leads) {
            state.leadsF = leads;
        },
        setLead(state, lead) {
            state.lead = lead;
        },
    },

    actions: {
        getLeads(ctx) {
            ctx.dispatch("getProfile");
            API.GET("lead/list").then((res) => {
                console.log("getLeads", res.data);
                ctx.commit("setLeads", res.data.leads);
            });
        },

        fillLead(ctx) {
            ctx.commit("setLeadsF", ctx.state.leads);
        },

        findLead(ctx, s) {
            if (!ctx.state.leads) {
                return null;
            }

            let list = ctx.state.leads;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let event = null;
                    let name = null;
                    let secondname = null;
                    let email = null;
                    let phone = null;

                    event = item.event.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    name = item.profile.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    secondname = item.profile.secondname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    email = item.profile.email.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    phone = item.profile.phone.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;

                    if (event || name || secondname || email || phone) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => s.status.includes(item.status));
            }

            if (list.length != ctx.state.leads.length) {
                s.clear = true;
            }

            ctx.commit("setLeadsF", list);
        },

        cancelLead(ctx, lead) {
            let formData = new FormData();
            formData.append("id", lead.id);
            API.POST("leadscribe/cancel", formData).then((res) => {
                ctx.commit("setLeads", res.data.leads);
            });
        },
    },

    getters: {
        leads(state) {
            return state.leadsF;
        },
        lead(state) {
            return state.lead;
        },
    },
};
