<template>
    <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-black text-white">СОЗДАТЬ ФОРМУ</h3>

        <div class="flex flex-col p-10">
            <div class="grid gap-4">
                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Название</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="form.name" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Описание</label>
                    <textarea class="w-2/3 h-32 py-1 px-3 bg-slate-100 text-slate-500 rounded text-sm" v-model="form.description"> </textarea>
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Ссылка</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-sm" v-model="form.url" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Остановить с</label>
                    <input type="date" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-sm" v-model="form.stop_at" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Статус</label>
                    <div class="btn btn-grey" :class="{ '!bg-green-400 ': form.status == 1 }" @click="form.status = !form.status">
                        <span v-if="form.status == 1">Активна</span>
                        <span v-else>Выключена</span>
                    </div>
                </div>

                <div class="flex justify-end mt-8">
                    <div class="btn btn-green py-2 px-6" @click="saveForm(form), closePopup()">Сохранить</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "AddEvent",

    data() {
        return {
            form: { status: 1 },
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s"]),
    },

    methods: {
        ...mapActions(["saveForm", "closePopup"]),
    },
};
</script>
